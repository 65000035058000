import React from 'react'
import { Link } from 'gatsby'
import { MainWrapper } from '../../LetsTalk/Calendar/calendar.style';
import { ActiveStatus } from '../../Portfolio/Contact/contact.style';
import Image from 'reusecore/src/elements/Image';
import Author from 'common/src/assets/image/mario-markovic-avatar.jpg';
import Container from 'common/src/components/UI/Container';

const ThankYou = () => {
    return (
        <MainWrapper>
            <Container noGutter mobileGutter width="1200px">
                <ActiveStatus className="avatar-big">
                    <Image src={Author} alt="Mario Markovic Avatar" />
                </ActiveStatus>
                <h1>Meeting scheduled!</h1>
                <p>You are scheduled with Mario Markovic.</p>
                <h2>A calendar invitation has been sent to your email address.</h2>
                <p>Video call url will be sent to you <strong>1 hour</strong> before the meeting.</p>
                <br />
                <Link to="/" className='portfolio_button'>Back to Home</Link>
            </Container>
        </MainWrapper>
    )
}

export default ThankYou