import React, { Fragment } from 'react';
import SEO from '../components/seo';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Portfolio/portfolio.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import ThankYou from '../containers/Scheduled/ThankYou'

const Scheduled = () => {
  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <SEO title="Meeting scheduled | Mario Markovic" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar className="nav-static" />
            </DrawerProvider>
          </Sticky>
          <ThankYou />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default Scheduled;
