import styled from 'styled-components';

export const ActiveStatus = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  margin-right: 20px;
  display: inline-block;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #42b72a;
    border: 2px solid #fff;
    right: -2px;
    bottom: -2px;
  }

  &.small-status {
    &:before {
      width: 14px;
      height: 14px;
    }
  }

  &.avatar-big {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    border-radius: 50%;
  }
`;
